import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Stack, Typography } from '@mui/material';
import React, { memo } from 'react';
import { DiscardChangesModal } from '../../../../../../../../components';
import { useMealActions } from '../../../../../../../../store/meal-store';
import { Burner, GrillStep } from '../../../../../../../types';
import { useRecipeComposerActions } from '../../../../../../hooks';
import { useRemovePreset } from '../../mutations';
import {
  BurnerTemperature,
  BurnerTimer,
  Notifications,
  PresetActions,
  PresetInfo,
  Presets,
} from './components';
import { useGenerateTriggers } from './hooks';

type Props = {
  mealId: string;
  preset: GrillStep;
  ordinal: number;
  probes?: Burner[];
  burners?: Burner[];
  isDragging?: boolean;
  onCloseEdit?: () => void;
  isEdit?: boolean;
};

const TemperaturePreset: React.FC<Props> = ({
  mealId,
  preset,
  ordinal,
  isEdit,
  probes,
  isDragging,
  onCloseEdit,
}) => {
  const { removePreset } = useMealActions();
  const { isOpen, handleClose, handleOpen } = useRecipeComposerActions();
  const { handleOnRemove, isLoading } = useRemovePreset();
  const triggers = useGenerateTriggers({ preset });
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: preset.id || '',
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleRemovePreset = () => {
    removePreset(mealId, preset.id);
    handleClose();
  };

  const handleDeleteStep = () => {
    handleOnRemove({ mealId, presetId: preset.id });
    handleClose();
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      display='flex'
      flexDirection='column'
      sx={{
        overflowY: 'auto',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.40)',
        borderRadius: '2px',
        bgcolor: 'kqn.white',
        px: 3,
      }}
    >
      <Stack direction='row' gap={3} sx={{ width: '100%', py: 1.5 }}>
        <Stack direction='column' width='100%' gap={2}>
          <Stack direction='row' gap={2} alignItems='center' justifyContent='space-between'>
            <Stack direction='row' gap={1} alignItems='center'>
              <DragIndicatorIcon {...listeners} sx={{ color: 'kqn.darkerGray', cursor: 'grab' }} />
              <Typography variant='body1' sx={{ fontWeight: '600', color: 'kqn.darkerGray' }}>
                Step {ordinal} {preset.isDraft ? '(Draft)' : '(Editing)'}
              </Typography>
            </Stack>
            <PresetActions
              isLoading={isLoading}
              mealId={mealId}
              preset={preset}
              onOpen={handleOpen}
              onClose={onCloseEdit}
            />
          </Stack>
          <PresetInfo mealId={mealId} step={preset} />
          <Stack direction='row' gap={2} justifyContent='space-between' width='100%'>
            <Stack width='50%'>
              <Presets
                step={preset}
                mealId={mealId}
                preset={preset.preset || ''}
                stepId={preset.id}
                mode={preset.mode}
                method={preset.method}
                initialProbes={probes}
              />
            </Stack>
            <Stack width='50%'>
              <BurnerTimer
                title='Grilling Time, s'
                mealId={mealId}
                stepId={preset.id}
                durations={preset.durations}
                isDisabled={(preset?.notifications || []).length > 0 || preset.lockDurations}
              />
            </Stack>
          </Stack>
          {/* TODO: refactor this and remove probes */}
          <BurnerTemperature
            mealId={mealId}
            burners={preset.burners}
            probes={
              preset.preset === 'Multiple'
                ? preset.probes?.filter((p) => p.name !== 'Default')
                : preset.probes
            }
            step={preset}
          />
          {!isDragging && (
            <Notifications
              mealId={mealId}
              isEdit={isEdit}
              presetId={preset.id}
              triggers={triggers}
              method={preset.method}
              isDisabled={preset.isDraft}
              stepNotifications={preset.notifications}
            />
          )}
        </Stack>
      </Stack>
      <DiscardChangesModal
        isOpen={isOpen}
        onClose={handleClose}
        title='Are you sure you want to remove this grill step?'
        content='This step will be removed permanently.'
        onConfirm={preset.isDraft ? handleRemovePreset : handleDeleteStep}
      />
    </Box>
  );
};

export default memo(TemperaturePreset);
