import { rectSortingStrategy } from '@dnd-kit/sortable';
import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { DragNDropContext } from '../../../../../../components';
import { useHandleDragEnd } from '../../../../../../shared';
import { RecipePreparation } from '../../../../../types';
import { useUpdatePreparationsOrdinals } from '../../../../mutations';
import PreparationSkeleton from './components/preparation-skeleton';
import PreparationStepView from './components/preparation-step-view';

type Props = {
  preparations?: RecipePreparation[];
  onUpdate: (preparations: RecipePreparation[]) => void;
  onRemove: (id: string) => void;
  isLoading?: boolean;
};

const Preparations: React.FC<Props> = ({ preparations, onUpdate, onRemove, isLoading }) => {
  const { handleUpdatePreparationsOrdinals } = useUpdatePreparationsOrdinals();
  const hasDraft = useMemo(() => preparations?.some((p) => p.isDraft), [preparations]);

  const handleOnUpdate = async (updated: RecipePreparation[]) => {
    onUpdate(updated);
    await handleUpdatePreparationsOrdinals(updated);
  };

  const handleDragEnd = useHandleDragEnd<RecipePreparation>({
    data: preparations || [],
    onUpdate: handleOnUpdate,
  });

  if (!preparations) return null;
  if (isLoading) return <PreparationSkeleton />;

  return (
    <Stack
      data-testid='preparations'
      width='100%'
      gap={2}
      sx={{ px: '1px', py: '1px', overflowY: 'auto', height: 'calc(100vh - 320px)' }}
    >
      <DragNDropContext
        items={preparations}
        onDragEnd={handleDragEnd}
        strategy={rectSortingStrategy}
        isDisabled={hasDraft}
      >
        {preparations?.map((preparation, idx) => (
          <PreparationStepView
            key={preparation.id}
            ordinal={idx + 1}
            preparation={preparation}
            onRemove={onRemove}
          />
        ))}
      </DragNDropContext>
    </Stack>
  );
};
export default Preparations;
