import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { memo, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  DiscardChangesModal,
  KQNIconButton,
  MutationIndicator,
} from '../../../../../../../components';
import { queries } from '../../../../../../queries';
import { CreateRecipePreparationRequest, RecipePreparation } from '../../../../../../types';
import { useRecipeComposerActions } from '../../../../../hooks';
import { useRemovePreparationStep } from '../mutations';
import { useCreatePreparationStep } from '../mutations/use-create-preparation-step';
import PreparationIngredients from './preparation-ingredients';
import PreparationStepInfo from './preparation-step-info';

type Props = {
  step: RecipePreparation;
  ordinal: number;
  onRemove: (id: string) => void;
  handleOnEditClick: () => void;
};

const PreparationStep: React.FC<Props> = ({ ordinal, step, onRemove, handleOnEditClick }) => {
  const [params] = useSearchParams();
  const recipeId = params.get('recipeId');
  const { isOpen, handleClose, handleOpen } = useRecipeComposerActions();
  const [preparation, setPreparation] = useState(step);
  const { handleRemovePreparation, isLoading } = useRemovePreparationStep();
  const { handleCreatePreparation, isLoading: isSaving } = useCreatePreparationStep();
  const [ingredientIds, setIngredientIds] = useState<string[]>(
    step?.ingredients?.map((ing) => ing.id) || [],
  );

  const { data: groups, isFetching } = useQuery({
    ...queries.groups.details(recipeId || undefined),
    enabled: !!recipeId,
  });

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: step.id || '',
    transition: {
      duration: 1000, // milliseconds
      easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleOnRemove = () => {
    onRemove(preparation.id || '');
  };

  const handleOnDelete = async () => {
    if (!preparation.id) return;

    await handleRemovePreparation({ stepId: preparation.id });
  };

  const handleUpdateIngredientIds = (ids: string[]) => {
    if (ingredientIds.length === 0 && ids.length === 0) return;

    setIngredientIds(ids);
    setPreparation((prev) => ({
      ...prev,
      isDraft: true,
    }));
  };

  const handleSubmit = async () => {
    const data: CreateRecipePreparationRequest = {
      ...preparation,
      ingredients: ingredientIds,
    };

    await handleCreatePreparation({
      preparation: data,
      onSuccess: () => {
        setPreparation((prev) => ({
          ...prev,
          isDraft: false,
          isNew: false,
        }));
      },
    });
  };

  const handleUpdatePreparationValue = useCallback(
    (field: keyof RecipePreparation, value: string) => {
      setPreparation((prev) => ({
        ...prev,
        [field]: value,
        isDraft: true,
      }));
    },
    [],
  );

  return (
    <Stack
      ref={setNodeRef}
      style={style}
      {...attributes}
      width='100%'
      gap={1}
      direction='column'
      px={3}
      py={1.5}
      sx={{
        bgcolor: 'kqn.white',
        borderRadius: '2px',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.40)',
      }}
    >
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' alignItems='center' gap={1}>
          <DragIndicatorIcon
            {...listeners}
            className='icon'
            sx={{ color: 'kqn.darkerGray', cursor: 'grab' }}
          />
          <Typography variant='body2' sx={{ fontWeight: 600, color: 'kqn.darkerGray' }}>
            Step {ordinal} {preparation.isDraft && '(Draft)'}
          </Typography>
        </Stack>
        <Stack direction='row' gap={1}>
          <KQNIconButton onClick={handleOnEditClick}>
            <ModeEditOutlineOutlinedIcon sx={{ color: 'kqn.cooper' }} />
          </KQNIconButton>
          {preparation.isDraft && (
            <KQNIconButton onClick={handleSubmit} isDisabled={isSaving || isLoading}>
              <CheckIcon sx={{ color: 'kqn.green' }} />
            </KQNIconButton>
          )}
          <KQNIconButton onClick={handleOpen} isDisabled={isLoading}>
            <DeleteOutlineIcon sx={{ color: 'kqn.coral' }} />
          </KQNIconButton>
        </Stack>
      </Stack>
      <PreparationStepInfo preparation={preparation} onChange={handleUpdatePreparationValue} />
      {isFetching ? (
        <div>Loading...</div>
      ) : (
        <PreparationIngredients
          groups={groups}
          handleUpdateIngredients={handleUpdateIngredientIds}
          ingredients={ingredientIds}
        />
      )}
      <MutationIndicator isMutating={isLoading || isSaving} />
      <DiscardChangesModal
        isOpen={isOpen}
        onClose={handleClose}
        title='Are you sure you want to remove this step?'
        content='This step will be removed permanently.'
        onConfirm={preparation.isDraft ? handleOnRemove : handleOnDelete}
      />
    </Stack>
  );
};

export default memo(PreparationStep);
