import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { QueryKeys } from '../../../../../../../constants';
import { useErrorContext } from '../../../../../../../context';
import { createErrorMap } from '../../../../../../../shared';
import { queries } from '../../../../../../queries';
import { CreateRecipePreparationRequest, RecipePreparation } from '../../../../../../types';
import { createPreparationSchema } from '../schema';
import { preparationService } from '../service';

interface Data {
  preparation: CreateRecipePreparationRequest;
}

interface Props {
  onSuccess?: () => void;
}

export const useCreatePreparationStep = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get('recipeId');
  const { setError } = useErrorContext();

  const mutation = useMutation<RecipePreparation, any, Data>(({ preparation }) =>
    preparationService.addPreparationStep(preparation, recipeId || undefined),
  );

  const handleCreatePreparation = async ({ preparation, onSuccess }: Data & Props) => {
    const isValid = createPreparationSchema.safeParse(preparation);
    if (!isValid.success) {
      const errors = createErrorMap(isValid.error, preparation.id);
      setError(errors);
      return;
    }
    setError(new Map());

    mutation.mutate(
      { preparation },
      {
        onSuccess: (data) => {
          queryClient.setQueryData(
            [QueryKeys.preparations, recipeId],
            (prevData?: RecipePreparation[]) => {
              if (!prevData) return [data];
              const index = prevData.findIndex((p) => p.id === data.id);
              if (index > -1) {
                // eslint-disable-next-line no-param-reassign
                prevData[index] = {
                  ...data,
                  isDraft: false,
                };
                return prevData;
              }
              return [...prevData, { ...data, isDraft: false }];
            },
          );
          queryClient.invalidateQueries(queries.preparations.all(recipeId!));
          queryClient.invalidateQueries(queries.recipes.info._def);
          toast.success(
            preparation.isNew
              ? 'Preparation step successfully created'
              : 'Preparation step successfully updated',
          );
          onSuccess?.();
        },
        onError: (err: any) => {
          toast.error(err || 'Something went wrong');
        },
      },
    );
  };

  return {
    handleCreatePreparation,
    isLoading: mutation.isLoading,
  };
};
