import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import { NavigationPaths } from '../../constants';
import { AccessoriesIcon, CategoriesIcon, IngredientsIcon, RecipesIcon } from '../../shared';
import MenuListItems from './menu-list-items';
import { ListItemType } from './sidebar.types';

const recipeMenuItems: ListItemType[] = [
  {
    text: 'Overview',
    icon: <RecipesIcon className='icon' />,
    url: NavigationPaths.OVERVIEW,
    testId: 'recipes-btn',
    path: ['overview', 'details', 'create'],
  },
  {
    text: 'Categories',
    icon: <CategoriesIcon className='icon' />,
    url: NavigationPaths.CATEGORIES,
    path: 'categories',
  },
  {
    text: 'Ingredients',
    icon: <IngredientsIcon className='icon' />,
    url: NavigationPaths.INGREDIENTS,
    path: 'ingredients',
  },
  {
    text: 'Accessories',
    icon: <AccessoriesIcon className='icon' />,
    url: NavigationPaths.ACCESSORIES,
    path: ['accessories'],
  },
  {
    text: 'Units',
    icon: <Icon icon='ion:scale-outline' className='icon' />,
    url: NavigationPaths.UNITS,
    path: 'units',
  },
];

type Props = {
  open: boolean;
};

const RecipeMenuItems: React.FC<Props> = ({ open }) => {
  return (
    <>
      {open && (
        <Typography
          px={2.5}
          py={1}
          variant='body1'
          align='left'
          sx={{ color: 'kqn.darkerGray', fontWeight: 600 }}
        >
          RECIPES
        </Typography>
      )}
      <MenuListItems data={recipeMenuItems} open={open} />
    </>
  );
};

export default RecipeMenuItems;
