import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useMealStore } from '../../../../../../../../../../../store/meal-store';
import { queries } from '../../../../../../../../../../queries';
import { Duration, Notification } from '../../../../../../../../../../types';
import { notificationService } from '../../../service';

interface Data {
  stepId: string;
  notification: Notification;
}

interface Props {
  mealId: string;
  onUpdate?: (notificationId: string, notification: Notification) => void;
}

export const useUpsertNotification = () => {
  const queryClient = useQueryClient();
  const { updateDurations } = useMealStore.use.actions();

  const mutation = useMutation<
    { notifications: Notification; durations: Duration[] },
    unknown,
    Data
  >(({ stepId, notification }) => notificationService.updateNotification(stepId, notification));

  const handleUpdateNotification = async ({
    mealId,
    stepId,
    notification,
    onUpdate,
  }: Data & Props) => {
    mutation.mutate(
      { stepId, notification },
      {
        onSuccess: ({ durations, notifications }) => {
          queryClient.invalidateQueries(queries.meals.filter._def);
          toast.success(
            notification.isDraft ? 'Substep successfully created' : 'Substep successfully updated',
          );
          updateDurations(mealId, stepId, durations);

          if (onUpdate) {
            onUpdate(notification.id, notifications);
          }
        },
        onError: (error) => {
          toast.error(error as string);
        },
      },
    );
  };

  return {
    handleUpdateNotification,
    isLoading: mutation.isLoading,
    mutation,
  };
};
