import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { queries } from '../../../../../../queries';
import { recipeService } from '../../../../../services';

export const useCreateGeneral = () => {
  const queryClient = useQueryClient();
  const [_searchParams, setSearchParams] = useSearchParams();

  const mutation = useMutation<{ id: string }, unknown, FormData>((data) =>
    recipeService.createRecipe(data),
  );

  const handleCreateGeneral = async (data: FormData, isDraft?: boolean) => {
    await mutation.mutateAsync(data, {
      onSuccess: ({ id: recipeId }) => {
        queryClient.refetchQueries(queries.recipes.infinityFilter._def);
        queryClient.refetchQueries(queries.recipes.filter._def);
        queryClient.invalidateQueries(queries.recipes.details._def);
        setSearchParams({ recipeId });
        toast.success(
          isDraft
            ? 'General recipe info successfully updated'
            : 'General recipe info successfully saved',
        );
      },
      onError: (err: any) => {
        toast.error(err || 'Something went wrong');
      },
    });
  };

  return {
    handleCreateGeneral,
    isLoading: mutation.isLoading,
    mutation,
  };
};
