import { Stack } from '@mui/material';
import { Title } from '../../../../../../../../../../components';
import { InputView } from '../../../../../../../../../../shared';
import { MethodType, Notification } from '../../../../../../../../../types';
import { shorterBurnerName } from '../burner-timer/helpers/shorter-burner-name';

type Props = { method?: MethodType; notification?: Notification };

const PresetNotificationView: React.FC<Props> = ({ method, notification }) => {
  if (!notification) return null;

  return (
    <Stack direction='column' gap={1}>
      <Stack direction='row' justifyContent='space-between' gap={2} sx={{ width: '100%' }}>
        <Stack direction='column' gap={1} width='100%'>
          <Title title='Message' />
          <InputView adornment='EN' placeholder='Message (EN)' value={notification.message} />
          <InputView adornment='DE' placeholder='Message (DE)' value={notification.messageDe} />
        </Stack>
        <Stack direction='column' gap={1} width='100%'>
          <Title title='Notification' />
          <InputView
            adornment='EN'
            placeholder='Notification (EN)'
            value={notification.inAppMessage}
          />
          <InputView
            adornment='DE'
            placeholder='Notification (DE)'
            value={notification.inAppMessageDe}
          />
        </Stack>
      </Stack>
      <Stack
        direction='row'
        justifyContent='space-between'
        gap={2}
        width='100%'
        alignItems='center'
      >
        <Stack direction='row' gap={1} width='100%' alignItems='center' />
        <Stack direction='column' gap={1} width='100%'>
          <Title title={method?.name === 'Probe' ? 'Probe Temperature (°C)' : 'Grilling Time, s'} />
          <Stack direction='row' gap={2} width='100%' alignItems='center'>
            {notification.triggers?.map((field, idx) => (
              <InputView
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                sx={{ maxWidth: '50px' }}
                value={field.value}
                adornment={shorterBurnerName(field.name as keyof typeof shorterBurnerName) || ''}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PresetNotificationView;
