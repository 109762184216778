import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Stack, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { KQNIconButton } from '../../../../../../../components';
import { InputView } from '../../../../../../../shared';
import { RecipePreparation } from '../../../../../../types';
import PeparationIngredientsPicker from './preparation-ingredients-picker';
import PreparationStep from './preparation-step';

type Props = {
  preparation: RecipePreparation;
  ordinal: number;
  onRemove: (id: string) => void;
};

const PreparationStepView: React.FC<Props> = ({ preparation, ordinal, onRemove }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: preparation.id || '',
    transition: {
      duration: 1000, // milliseconds
      easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleOnEditClick = useCallback(() => {
    setIsEdit((prev) => !prev);
  }, []);

  if (isEdit)
    return (
      <PreparationStep
        ordinal={ordinal}
        step={preparation}
        onRemove={onRemove}
        handleOnEditClick={handleOnEditClick}
      />
    );

  return (
    <Stack
      ref={setNodeRef}
      style={style}
      {...attributes}
      width='100%'
      gap={1}
      direction='column'
      px={3}
      py={1.5}
      sx={{
        bgcolor: 'kqn.white',
        borderRadius: '2px',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.40)',
      }}
    >
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' alignItems='center' gap={1}>
          <DragIndicatorIcon
            {...listeners}
            className='icon'
            sx={{ color: 'kqn.darkerGray', cursor: 'grab' }}
          />
          <Typography variant='body2' sx={{ fontWeight: 600, color: 'kqn.darkerGray' }}>
            Step {preparation.ordinal} {preparation.isDraft && '(Draft)'}
          </Typography>
        </Stack>
        <Stack direction='row' gap={1}>
          <KQNIconButton onClick={handleOnEditClick}>
            <ModeEditOutlineOutlinedIcon sx={{ color: 'kqn.cooper' }} />
          </KQNIconButton>
        </Stack>
      </Stack>
      <Stack direction='row' gap={2} width='100%'>
        <InputView
          adornment='EN'
          value={preparation.description}
          inputProps={{ width: '100%' }}
          sx={{ width: '100%' }}
        />
        <InputView
          adornment='DE'
          value={preparation.descriptionDe}
          sx={{ width: '100%' }}
          inputProps={{ width: '100%' }}
        />
      </Stack>
      <Stack direction='row' gap={1}>
        <PeparationIngredientsPicker
          data={preparation.ingredients?.map((i) => i.ingredient) || []}
        />
      </Stack>
    </Stack>
  );
};

export default PreparationStepView;
