import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useErrorContext } from '../../../../../../../context';
import { createErrorMap } from '../../../../../../../shared';
import { queries } from '../../../../../../queries';
import { GrillStep } from '../../../../../../types';
import { upsertGrillStepSchema } from '../schema/upsert-grill-step-schema';
import { grillService } from '../service';

interface Data {
  mealId: string;
  preset: GrillStep;
}

interface Props {
  onUpdate?: (mealId: string, presetId: string, preset: GrillStep) => void;
  onCleanup?: () => void;
}

export const useUpsertPreset = () => {
  const queryClient = useQueryClient();
  const { setError, resetError } = useErrorContext();

  const mutation = useMutation<GrillStep, unknown, Data>(({ mealId, preset }) =>
    grillService.upsertGrillStep(mealId, preset),
  );

  const handleUpsertPreset = async ({ mealId, preset, onUpdate, onCleanup }: Data & Props) => {
    try {
      const isValid = upsertGrillStepSchema.safeParse(preset);

      if (!isValid.success) {
        const errors = createErrorMap(isValid.error, preset.id);
        setError(errors);
        return;
      }
    } catch (err: any) {
      toast.error('Grill step is not valid');
    }

    resetError();

    mutation.mutate(
      { mealId, preset },
      {
        onSuccess: (data) => {
          if (onUpdate) {
            onUpdate(mealId, preset.id, data);
          }
          queryClient.invalidateQueries(queries.meals.filter._def);
          toast.success(
            preset.isDraft
              ? 'Grilling step successfully created'
              : 'Grilling step successfully updated',
          );
          onCleanup?.();
        },
        onError: (err: any) => {
          toast.error(err || 'Something went wrong');
        },
      },
    );
  };

  return { handleUpsertGrillStep: handleUpsertPreset, isLoading: mutation.isLoading, mutation };
};
