import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, Stack, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { KQNIconButton } from '../../../../../../../../components';
import { Burner, GrillStep } from '../../../../../../../types';
import { InfoStep } from '../info-step';
import { TemperaturePreset } from '../preset';
import { ForceStop, InstantStart, Notifications } from '../preset/components';
import { useGenerateTriggers } from '../preset/hooks';
import { PresetInfoView } from './components';
import BurnerTemperatureView from './components/burner-temperature-view';
import BurnerTimerView from './components/burner-timer-view';
import InfoStepView from './components/info-step-view';
import PresetsView from './components/presets-view';

type Props = {
  mealId: string;
  preset: GrillStep;
  ordinal: number;
  probes?: Burner[];
  burners?: Burner[];
  isDragging?: boolean;
};

const TemepraturePresetView: React.FC<Props> = ({
  preset,
  probes,
  mealId,
  ordinal,
  isDragging,
}) => {
  const { isDraft, preset: presetMode, method, mode } = preset;
  const [isEdit, setIsEdit] = useState(false);
  const isInfoStep = preset.type === 'info';
  const triggers = useGenerateTriggers({ preset });

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: preset.id || '',
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleOnEditClick = useCallback(() => {
    setIsEdit((prev) => !prev);
  }, []);

  if (isEdit || preset.isDraft) {
    if (preset.type === 'info')
      return (
        <InfoStep
          mealId={mealId}
          ordinal={ordinal}
          preset={preset}
          onCloseEdit={handleOnEditClick}
        />
      );

    return (
      <TemperaturePreset
        isEdit={isEdit}
        preset={preset}
        probes={probes}
        mealId={mealId}
        ordinal={ordinal}
        isDragging={isDragging}
        onCloseEdit={handleOnEditClick}
      />
    );
  }

  if (preset.type === 'info') return <InfoStepView preset={preset} onEdit={handleOnEditClick} />;

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      display='flex'
      flexDirection='column'
      sx={{
        overflowY: 'auto',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.40)',
        borderRadius: '2px',
        bgcolor: 'kqn.white',
        px: 3,
      }}
    >
      <Stack direction='row' gap={3} sx={{ width: '100%', py: 1.5 }}>
        <Stack direction='column' width='100%' gap={2}>
          <Stack direction='row' gap={2} alignItems='center' justifyContent='space-between'>
            <Stack direction='row' gap={1} alignItems='center'>
              <DragIndicatorIcon {...listeners} sx={{ color: 'kqn.darkerGray', cursor: 'grab' }} />
              <Typography variant='body1' sx={{ fontWeight: 600, color: 'kqn.darkerGray' }}>
                Step {ordinal} {isDraft && '(Draft)'}
              </Typography>
            </Stack>
            <Stack direction='row' gap={4}>
              <InstantStart
                isInstant={preset.instant}
                mealId={mealId}
                stepId={preset.id}
                isDisabled
              />
              <ForceStop
                forceStop={preset.forceStop}
                mealId={mealId}
                stepId={preset.id}
                isDisabled
              />
              <KQNIconButton onClick={handleOnEditClick}>
                <ModeEditOutlineOutlinedIcon sx={{ color: 'kqn.cooper' }} />
              </KQNIconButton>
            </Stack>
          </Stack>
          <PresetInfoView preset={preset} />
          <Stack direction='row' gap={2} justifyContent='space-between' width='100%'>
            <PresetsView preset={presetMode || ''} mode={mode!} method={method!} />
            <BurnerTimerView
              title={isInfoStep ? 'Grilling Time, s' : 'Duration'}
              durations={preset.durations}
            />
          </Stack>
          <BurnerTemperatureView
            burners={preset.burners}
            probes={
              preset.preset === 'Multiple'
                ? preset.probes?.filter((p) => p.name !== 'Default')
                : preset.probes
            }
            step={preset}
          />
          {!!triggers.length && (
            <Notifications
              mealId={mealId}
              presetId={preset.id}
              triggers={triggers}
              method={preset.method}
              isDisabled
              stepNotifications={preset.notifications}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default TemepraturePresetView;
