import { zodResolver } from '@hookform/resolvers/zod';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  KQNTextField,
  KQNTextInputField,
  TextInputAndormnet,
  Title,
} from '../../../../../../../../../../components';
import { MethodType, Notification } from '../../../../../../../../../types';
import { shorterBurnerName } from '../burner-timer/helpers/shorter-burner-name';
import { useUpsertNotification } from './mutations';
import PresetNotificationView from './preset-notification-view';
import { NotificationFormType, notificationSchema } from './schema';

type Props = {
  stepId: string;
  mealId: string;
  method?: MethodType;
  notification?: Notification;
  isEdit?: boolean;
  onUpdate?: (oldNotificationId: string, notification: Notification) => void;
};

const PresetNotification: React.FC<Props> = ({
  mealId,
  method,
  stepId,
  isEdit,
  notification,
  onUpdate,
}) => {
  const { handleUpdateNotification } = useUpsertNotification();
  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm<NotificationFormType>({
    resolver: zodResolver(notificationSchema),
    defaultValues: {
      title: notification?.title || '',
      titleDe: notification?.titleDe || '',
      message: notification?.message || '',
      messageDe: notification?.messageDe || '',
      inAppMessage: notification?.inAppMessage || '',
      inAppMessageDe: notification?.inAppMessageDe || '',
      triggers: notification?.triggers || [],
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'triggers',
  });

  const handleSave = async (data: NotificationFormType) => {
    if (!notification) return;

    await handleUpdateNotification({
      mealId,
      stepId,
      notification: { ...data, id: notification.id, isDraft: notification.isDraft },
      onUpdate,
    });
  };

  useEffect(() => {
    if (notification) {
      reset({
        title: notification.title || '',
        titleDe: notification.titleDe || '',
        message: notification.message || '',
        messageDe: notification.messageDe || '',
        inAppMessage: notification.inAppMessage || '',
        inAppMessageDe: notification.inAppMessageDe || '',
        triggers: notification.triggers || [],
      });
    }
  }, [notification, reset]);
  if (!notification) return null;
  if (!isEdit) return <PresetNotificationView method={method} notification={notification} />;

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Stack direction='column' gap={1}>
        <Stack direction='row' justifyContent='space-between' gap={2} sx={{ width: '100%' }}>
          <Stack direction='column' gap={1} width='100%'>
            <Title title='Message' />
            <KQNTextInputField
              name='message'
              control={control}
              placeholder='Message (EN)'
              InputProps={{
                startAdornment: <TextInputAndormnet text='EN' position='start' />,
              }}
            />
            <KQNTextInputField
              name='messageDe'
              control={control}
              placeholder='Message (DE)'
              InputProps={{
                startAdornment: <TextInputAndormnet text='DE' position='start' />,
              }}
            />
          </Stack>
          <Stack direction='column' gap={1} width='100%'>
            <Title title='Notification' />
            <KQNTextInputField
              name='inAppMessage'
              placeholder='Notification (EN)'
              control={control}
              InputProps={{
                startAdornment: <TextInputAndormnet text='EN' position='start' />,
              }}
            />
            <KQNTextInputField
              name='inAppMessageDe'
              control={control}
              placeholder='Notification (DE)'
              InputProps={{
                startAdornment: <TextInputAndormnet text='DE' position='start' />,
              }}
            />
          </Stack>
        </Stack>
        <Stack
          direction='row'
          justifyContent='space-between'
          gap={2}
          width='100%'
          alignItems='center'
        >
          <Stack direction='row' gap={1} width='100%' alignItems='center'>
            <IconButton
              type='submit'
              sx={{
                width: '42px',
                mt: 2,
                height: '42px',
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
              }}
            >
              <CheckIcon sx={{ color: 'kqn.green' }} />
            </IconButton>
          </Stack>
          <Stack direction='column' gap={1} width='100%'>
            <Title
              title={method?.name === 'Probe' ? 'Probe Temperature (°C)' : 'Grilling Time, s'}
            />
            <Stack direction='row' gap={2} width='100%' alignItems='center'>
              {fields.map((field, idx) => {
                return (
                  <KQNTextField
                    {...register(`triggers.${idx}.value`, {
                      required: true,
                      valueAsNumber: true,
                      min: 0,
                    })}
                    key={field.id}
                    id={field.id}
                    type='number'
                    size='small'
                    variant='outlined'
                    onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                    error={!!errors.triggers?.[idx]?.value}
                    helperText={errors.triggers?.[idx]?.value?.message}
                    InputProps={{
                      inputProps: { min: 0 },
                      startAdornment: (
                        <TextInputAndormnet
                          text={
                            shorterBurnerName(field.name as keyof typeof shorterBurnerName) || ''
                          }
                          color='#201D1D'
                          position='start'
                        />
                      ),
                      sx: { bgcolor: 'kqn.white', width: '120px', height: '40px' },
                    }}
                  />
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

export default PresetNotification;
