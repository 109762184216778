import { Typography } from '@mui/material';
import { NavigationPaths } from '../../constants';
import { SettingsIcon, UsersIcon } from '../../shared';
import MenuListItems from './menu-list-items';
import { ListItemType } from './sidebar.types';

const userMenuItems: ListItemType[] = [
  {
    text: 'Users',
    icon: <UsersIcon className='icon' />,
    url: NavigationPaths.USERS,
    path: 'users',
  },
  {
    text: 'Settings',
    icon: <SettingsIcon className='icon' />,
    url: NavigationPaths.SETTINGS,
    path: 'settings',
  },
];

type Props = {
  open: boolean;
};

const UserMenuItems: React.FC<Props> = ({ open }) => {
  return (
    <>
      {open && (
        <Typography
          px={2.5}
          py={1}
          variant='body1'
          align='left'
          sx={{ color: 'kqn.darkerGray', fontWeight: 600 }}
        >
          ADMIN
        </Typography>
      )}
      <MenuListItems data={userMenuItems} open={open} />
    </>
  );
};

export default UserMenuItems;
